import { FaLanguage } from "react-icons/fa";
import { CgScreen } from "react-icons/cg";
import { BsTools } from "react-icons/bs";


import styles from "./Skills.module.css";

const Skills = () => {
  return (
    <section className={styles["skills-section"]} id="skills">
      <div>
        <FaLanguage className={styles.icon}/>
        <h2>Languages</h2>
        <div>
          <p>HTML</p>
          <p>CSS</p>
          <p>Javascript</p>
          <p>Python</p>
          <p>SQL</p>
        </div>
      </div>
      <div>
        <CgScreen className={styles.icon}/>
        <h2>Libraries, Frameworks, & DB's</h2>
        <div>
          <p>React</p>
          <p>Redux</p>
          <p>Node.js</p>
          <p>Express.js</p>
          <p>MongoDB</p>
          <p>Selenium</p>
          <p>Chrome Web Driver</p>
          <p>TailwindCSS</p>
          <p></p>
        </div>
      </div>
      <div>
      <BsTools className={styles.icon}/>
        <h2>Tools</h2>
        <div>
          <p>Vscode</p>
          <p>Git</p>
          <p>GitHub</p>
          <p>Netlify</p>
          <p>Vercel</p>
          <p>Postman</p>
          <p>Terminal</p>
          <p>Pen & Paper</p>
        </div>
      </div>
    </section>
  );
};

export default Skills;
