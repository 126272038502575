import React from 'react';

import styles from "./Popover.module.css";

const Popover = ({ text, onClose }) => {
  return (
    <div className={`${styles["popover-container"]} ${text ? styles.show : ""}`}>
      <button onClick={onClose}>X</button>
      <p>{text}</p>
    </div>
  );
};

export default Popover;