// import { useEffect, useState } from 'react';
// import { motion, useAnimation } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';

// const RevealOnScroll = ({ children }) => {
//   const controls = useAnimation();
//   const [ref, inView] = useInView({
//     threshold: 0.85, // Trigger animation when 50% of the element is visible
//   });

//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     if (inView) {
//       setIsVisible(true);
//     }
//   }, [inView]);

//   useEffect(() => {
//     if (!inView && isVisible) {
//       setIsVisible(false);
//       controls.start('hidden');
//     }
//   }, [controls, inView, isVisible]);

//   return (
//     <motion.div
//       ref={ref}
//       initial="hidden"
//       animate={isVisible ? 'visible' : controls}
//       variants={{
//         visible: { opacity: 1, y: 0 },
//         hidden: { opacity: 0, y: 50 },
//       }}
//       transition={{ duration: 0.8 }}
//     >
//       {children}
//     </motion.div>
//   );
// };

// export default RevealOnScroll;


import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const RevealOnScroll = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.60,
  });

  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [scrollingDown, setScrollingDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollingDown(currentScrollY > prevScrollY);
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    if (scrollingDown && !isVisible && inView) {
      setIsVisible(true);
    } else if (!scrollingDown && isVisible) {
      console.log("fully scrolled up")
      setIsVisible(false);
      controls.start('hidden');
    }
  }, [controls, inView, isVisible, scrollingDown]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isVisible ? 'visible' : controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
      transition={{ duration: 0.8 }}
    >
      {children}
    </motion.div>
  );
};

export default RevealOnScroll;