import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import styles from "./Contact.module.css";

import sentEmailIcon from "../../assets/images/icons/sentEmail.png";
import emailIcon from "../../assets/images/icons/email.png";

const Contact = () => {
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const formRef = useRef();

  const sendEmailHandler = (e) => {
    e.preventDefault();
    setIsEmailSending(true);

    const publicKey = "lhh1F3m6ePCbK7A3i";
    const serviceId = "service_te3co3b";
    const templateId = "template_18lvilh";

    emailjs.sendForm(serviceId, templateId, formRef.current, publicKey).then(
      (res) => {
        e.target.reset();
        setIsEmailSending(false);
        setIsMessageSent(true);
        setTimeout(() => {
          setIsMessageSent(false);
        }, 1500);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <section className={styles["contact-container"]} id="contact">
      <h2>
        I'm excited to hear from you!
        <br />
        Let's get started.
      </h2>

      <form
        className={styles["form-container"]}
        onSubmit={sendEmailHandler}
        ref={formRef}
      >
        <section>
          <img
            src={emailIcon}
            alt="Email icon"
            className={styles["email-icon"]}
          />

          <img
            src={sentEmailIcon}
            alt="Sent email icon"
            className={styles["sent-email-icon"]}
          />
        </section>
        <div>
          <div>
            <label htmlFor="name-input">Name</label>
            <br />
            <input type="text" id="name-input" name="name" />
          </div>
          <div>
            <label htmlFor="email-input">Email</label>
            <br />
            <input type="text" id="email-input" name="email" />
          </div>
          <div>
            <label htmlFor="subject-input">Subject</label>
            <br />
            <input type="text" id="subject-input" name="subject" />
          </div>
          <div>
            <label htmlFor="message-input">Message</label>
            <br />
            <textarea id="message-input" name="message" />

            <br />

            <button>
              {isEmailSending && "Message Sending.."}
              {isMessageSent && "Message Sent!"}
              {!isEmailSending && !isMessageSent && "Send"}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default Contact;
