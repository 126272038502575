import NavBar from "./components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
// import Skills from "./components/Skills/Skills";
import Experience from "./components/Experience/Experience";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <>
      <NavBar />
      <Hero />
      <About />
      {/* <Skills /> */}
      <Experience />
      <Projects />
      <Contact />
    </>
  );
}

export default App;
