import RevealOnScroll from "../RevealOnScroll/RevealOnScroll";
import ExperienceCard from "./ExperienceCard";

import styles from "./Experience.module.css";

const Experience = () => {
//   const [loadMore, setLoadMore] = useState(false);

  const myExperience = [
    {
      title: "Front-End Developer",
      company: "Persado",
      dates: "April 2022 - January 2023",
      duties: [
        "Build emails, landing pages, display banners, direct mail pieces, and Facebook ads in various formats (HTML, PSD, PDF, jpg, etc.) to support our clients’ customer communication campaigns",
        "Utilize multiple APIs and custom integrations with other services to deliver and serve content to and from our clients’ platforms",
        "Follow rigorous QA processes to ensure flawless delivery of all assets",
        "Ensure on-time and problem-free campaign launches by partnering closely with the Persado Operations and Product teams to understand project deliverables and timelines",
        "Make appropriate design decisions about line breaks, text size, positioning, and animation timing ",
      ],
    },
    {
      title: "Instructor Associate",
      company: "General Assembly",
      dates: "March 2022 - March 2023",
      duties: [
        "Host office hours multiple times a week to aid students with class topics and labs, HW, career questions, and anything else needed",
        "Meet consistently with students to review and address student progress and needs via HW, project review, and assessment",
        "Work cohesively with the instructional team and student success teams to inform and report student expectations",
        "Responsible for students receiving accurate, proactive, meaningful, and prompt feedback on their progress",
        "Work directly with students to help guide them through a rigorous, transformational journey towards junior roles in a new field",
      ],
    },
    {
      title: "Software Engineering Immersive Program",
      company: "General Assembly",
      dates: "March 2021 - June 2021",
      duties: [
        "Full-stack software engineering immersive student in an intensive, twelve-week, 420+ hour program focused on product development fundamentals, object-oriented programming, MVC frameworks, data modeling, and team collaboration strategies",
      ],
    },
  ];

  return (
    <section className={styles["experience-area"]} id="experience">
      <div className={styles["experience-intro"]}>
        <h2>My Experience</h2>
      </div>
      <div className={styles["experience-container"]}>
        {myExperience.map((exp, idx) => {
          return (
            <RevealOnScroll>
              <ExperienceCard exp={exp} idx={idx} />
            </RevealOnScroll>
          );
        })}
      </div>
    </section>
  );
};

export default Experience;
