import presentlyImg1 from "../images/projectImages/PresenTly/1.png";
import presentlyImg2 from "../images/projectImages/PresenTly/2.png";
import presentlyImg3 from "../images/projectImages/PresenTly/3.png";
import presentlyImg4 from "../images/projectImages/PresenTly/4.png";
import presentlyImg5 from "../images/projectImages/PresenTly/5.png";
import presentlyImg6 from "../images/projectImages/PresenTly/6.png";

import cookloreImg1 from "../images/projectImages/CookLore/1.png";
import cookloreImg2 from "../images/projectImages/CookLore/2.png";
import cookloreImg3 from "../images/projectImages/CookLore/3.png";
import cookloreImg4 from "../images/projectImages/CookLore/4.png";
import cookloreImg5 from "../images/projectImages/CookLore/5.png";
import cookloreImg6 from "../images/projectImages/CookLore/6.png";


import animeMemoryImg1 from "../images/projectImages/AnimeMemoryGame/1.png";
import animeMemoryImg2 from "../images/projectImages/AnimeMemoryGame/2.png";
import animeMemoryImg3 from "../images/projectImages/AnimeMemoryGame/3.png";
import animeMemoryImg4 from "../images/projectImages/AnimeMemoryGame/4.png";
import animeMemoryImg5 from "../images/projectImages/AnimeMemoryGame/5.png";
import animeMemoryImg6 from "../images/projectImages/AnimeMemoryGame/6.png";

import officeTriviaImg1 from "../images/projectImages/OfficeTriviaGame/1.png";
import officeTriviaImg2 from "../images/projectImages/OfficeTriviaGame/2.png";
import officeTriviaImg3 from "../images/projectImages/OfficeTriviaGame/3.png";
import officeTriviaImg4 from "../images/projectImages/OfficeTriviaGame/4.png";
import officeTriviaImg5 from "../images/projectImages/OfficeTriviaGame/5.png";



const projectData = [
    {
        title: "PresenTly",
        languagesUsed: "React, React Native, Typescript, NodeJS, MongoDB, Express.js, OpenAI, Jest, AWS, Figma.",
        caption: "Exciting application to aid in remembering loved one's birthdays and aid in recommending gift ideas.",
        link: "https://mypresently.online/",
        linkText: "mypresently.online",
        trophy: true,
        preview: true,
        trophyText: "PresenTly took first place in General Assembly's 2023 Autumn hackathon.",
        images: [presentlyImg1, presentlyImg2, presentlyImg3, presentlyImg4, presentlyImg5, presentlyImg6]
    },
    {
        title: "Cook Lore",
        languagesUsed: "React, NodeJS, MongoDB, Express.js, Figma, Netlify",
        caption: "Easy to use application to document family recipes and traditions.",
        link: "https://cooklore.netlify.app/",
        linkText: "cooklore.netlify",
        trophy: true,
        preview: true,
        trophyText: "Cook Lore took first place in General Assembly's 2023 Summer hackathon.",
        images: [cookloreImg1, cookloreImg2, cookloreImg3, cookloreImg4, cookloreImg5, cookloreImg6]
    },
    {
        title: "Anime Card Matching Game",
        languagesUsed: "HTML, CSS, Javascript, DOM Manipulation, Github deployment",
        caption: "A fun and simple Anime game to test your memory. Prince Zuko inspired landing page.",
        link: "https://max-v-walker.github.io/Anime-Card-Matching-Game/",
        linkText: "anime-card-matching.github.io",
        trophy: false,
        preview: true,
        images: [animeMemoryImg1, animeMemoryImg2, animeMemoryImg3, animeMemoryImg4, animeMemoryImg5, animeMemoryImg6]
    },
    {
        title: "JimmyJazz Sneaker Bot",
        languagesUsed: "Python, Selenium, Chrome Web Driver",
        caption: "Intelligent and simple solution to aid in the purchase of a limited, high in demand sneaker.",
        link: "https://github.com/Max-V-Walker/Jimmy-Jazz-Sneaker-Bot",
        linkText: "github-repository",
        trophy: false,
        preview: false
    },
    {
        title: "The Office Trivia Game",
        languagesUsed: "React",
        caption: "Entertaining trivia game for NBC's fan favorite show, The Office.",
        link: "https://office-trivia-game.vercel.app/",
        linkText: "office-trivia.vercel",
        trophy: false,
        preview: true,
        images: [officeTriviaImg1, officeTriviaImg2, officeTriviaImg3, officeTriviaImg4, officeTriviaImg5]
    },
    
    {
        title: "TikTok Bot",
        languagesUsed: "Python, Selenium, Chrome Web Driver",
        caption: "Easy to configure program, to increase views on any of a user's tiktoks.",
        link: "https://github.com/Max-V-Walker/Tik-Tok-Views-Bot",
        linkText: "github-repository",
        trophy: false,
        preview: false
    },
    // {},
    // {},
];

export default projectData;