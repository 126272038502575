import { useState } from "react";

import styles from "./Experience.module.css";

import FrontendDevIcon from "../../assets/images/icons/frontendDevIcon.png";
import InstructorIcon from "../../assets/images/icons/instructorIcon.png";
import BootcampIcon from "../../assets/images/icons/bootcampIcon.png";

const ExperienceCard = ({ exp, idx }) => {
  const [loadMore, setLoadMore] = useState(false);

  return (
    <div
      className={`${styles["one-experience"]} ${
        idx === 1 ? styles["move-right"] : ""
      } ${loadMore ? styles["load-more"] : ""}`}
    >
      <h2>
        {exp.title}{" "}
        <img
          src={`${idx === 0 ? FrontendDevIcon : ""} ${
            idx === 1 ? InstructorIcon : ""
          } ${idx === 2 ? BootcampIcon : ""}`}
          alt="thang"
          className={styles["experience-icon"]}
        />{" "}
      </h2>
      <p>{exp.company}</p>
      <p>{exp.dates}</p>
      <ul className={styles["load-more-ul"]}>
        {!loadMore && (
          <>
            <li>{exp.duties[0]}</li>
            {idx !== 2 && (
              <button
                onClick={() => setLoadMore(true)}
                className={styles["load-more-btn"]}
              >
                Load More
              </button>
            )}
          </>
        )}
      </ul>
        <ul className={`${loadMore ? styles["show-ul"] : styles["hide-ul"]}`}>
          {exp.duties.map((duty, index) => (
            <li>{duty}</li>
          ))}
          <button
            onClick={() => setLoadMore(false)}
            className={styles["load-more-btn"]}
          >
            Show Less
          </button>
        </ul>
    </div>
  );
};

export default ExperienceCard;
