import React, { useState } from 'react';
import Popover from './Popover';

import trophyIcon from "../../assets/images/icons/trophyIcon.png";

import styles from "./PopoverIcon.module.css";

const IconWithPopover = ({project}) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [popoverText, setPopoverText] = useState('');
  // const [iconPosition, setIconPosition] = useState({ top: 0, left: 0 });

  const handleIconClickHandler = (text, event) => {
    setPopoverText(text);
    // setIconPosition({
    //   top: event.clientY,
    //   left: event.clientX,
    // });
    setPopoverVisible(true);
  };

  const closePopoverHandler = () => {
    setPopoverVisible(false);
  };

  return (
    <div className={styles["icon-container"]}>
      <img src={trophyIcon} alt="Trophy" className={styles["trophy-icon"]} onClick={(e) => handleIconClickHandler(project.trophyText, e)} />
      {isPopoverVisible && (
        <Popover text={popoverText} onClose={closePopoverHandler} />
      )}
    </div>
  );
};

export default IconWithPopover;




