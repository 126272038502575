import { useState, useEffect } from "react";

import HamburgerMenuIcon from "../../assets/images/icons/hamburgerMenuIcon.png";

import CancelBurgerIcon from "../../assets/images/icons/cancelBurgerIcon.png";

import styles from "./NavBar.module.css";

const NavBar = () => {
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);

  const getWindowWidth = () => {
    const { innerWidth } = window;
    return innerWidth;
  };

  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const hamburgerClickHandler = () => {
    setIsBurgerClicked((preVal) => !preVal);
  };

  const burgerMenu = (
    <div className={styles["burger-menu"]} popover id="menu">
      <a href="#about" onClick={() => setIsBurgerClicked(false)}>
        About
      </a>
      <a href="#skills" onClick={() => setIsBurgerClicked(false)}>
        Skills
      </a>
      <a href="#experience" onClick={() => setIsBurgerClicked(false)}>
        Experience
      </a>
      <a href="#projects" onClick={() => setIsBurgerClicked(false)}>
        Projects
      </a>
    </div>
  );

  return (
    <header className={styles["header-container"]} id="nav">
      <nav className={styles["nav-bar"]}>
        <div>
          <a href="#about">About</a>
          <a href="#skills">Skills</a>
          <a href="#experience">Experience</a>
          <a href="#projects">Projects</a>
        </div>

        <div>
          <p>MW</p>
        </div>

        <div className={styles.contact}>
          <a href="#contact">Hit Me Up</a>
        </div>

        <div>
          {!isBurgerClicked && (
            <img
              src={HamburgerMenuIcon}
              alt="Hamburger Menu Icon"
              onClick={hamburgerClickHandler}
            />
          )}
          {isBurgerClicked && windowWidth < 600 && (
            <img
              src={CancelBurgerIcon}
              alt="Hamburger Menu Icon"
              className={styles["cancel-burger-menu"]}              
              onClick={hamburgerClickHandler}
            />
          )}
        </div>
      </nav>
      {isBurgerClicked && windowWidth < 600 && burgerMenu}
    </header>
  );
};

export default NavBar;
